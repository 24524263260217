<template>
  <section>
    <div v-if="loading" >
      <b-skeleton animation="fade" width="100%" />
      <b-skeleton animation="fade" width="100%" />
      <b-skeleton animation="fade" width="100%" />
    </div>
    <b-table-simple id="guides-table" responsive borderless fixed v-else>
      <thead>
        <tr v-for="(row, index) in rows" :key="index">
          <th v-show="index === 0" v-for="header in headers" :key="header" :id="header">
            <b-form-checkbox v-if="header === '' && checkable && row['Situação'] !=='Enviado'" v-model="selectAll" :key="selectAll">
            </b-form-checkbox>

            <b-form-checkbox v-if="header === '' && checkable && row['Situação'] ==='Enviado' && status === 'Recebido'" v-model="selectAll" :key="selectAll">
            </b-form-checkbox>

            <b-form-checkbox v-if="header === '' && checkable && row['Situação'] ==='Enviado' && status !== 'Recebido'" v-model="selectAll" :key="selectAll" :disabled="row['Situação'] ==='Enviado'">
            </b-form-checkbox>

            <b-form-checkbox v-if="header === '' && generateByselection" disabled />
            <span class="hide-btn" @click="() => hideRows = !hideRows" v-else-if="header === 'Ver menos'">
              {{ hideRows ? 'Ver mais' : 'Ver menos' }}
            </span>
            <span v-else>
              {{ header }}
            </span>
          </th>
        </tr>
      </thead>
      <tbody v-for="(row, index) in rows" :key="index">
        <tr v-if="!hideRows">
          <td v-for="(header, i) in headers" :key="header">
            <Icon v-if="i === 0 && removeSelGuide">
              <Trash class="icon trash" @click="removeSelectGuide(row)" />
            </Icon>
            <b-form-checkbox v-if="i === 0 && checkable && (row['Situação'] !=='Enviado')" v-model="rows[index]['checked']"
              @change="handleCheckbox(rows[index]['checked'])">
            </b-form-checkbox>

            <b-form-checkbox v-if="i === 0 && checkable && (row['Situação'] ==='Enviado' && status === 'Recebido')" v-model="rows[index]['checked']"
              @change="handleCheckbox(rows[index]['checked'])">
            </b-form-checkbox>
            
            <b-form-checkbox v-if="i === 0 && checkable && row['Situação'] ==='Enviado' && status !== 'Recebido'" v-model="rows[index]['checked']"
               v-b-tooltip.hover title="Altere a situação do lote para recebido"
              :disabled="row['Situação'] ==='Enviado'">
            </b-form-checkbox>

            <b-form-checkbox v-else-if="i === 0 && generateByselection"
              :checked="selecteds.indexOf(row) === -1 ? false : true" @change="selectRow(row)" v-b-tooltip.hover
              :title="row['Lote'] === 'Sim' ?
              `Essa guia já está presente em um lote gerado.\n Exclua o lote Nº${row['Nº Fatura']} com a guia, caso deseje incluí-la em um novo lote` :
              selecteds.length === maxGuidesPerBatch ? `Convênio aceita apenas ${maxGuidesPerBatch} guias por lote`: ''"
              :disabled="row['Lote'] === 'Sim' || !!selectedType && (selectedType !== row['Tipo de guia'] || (selecteds.length === maxGuidesPerBatch) && selecteds.indexOf(row) === -1) ? true : false">
            </b-form-checkbox>

            <span v-else-if="header === 'Situação'" class="situation-tag" :style="`background: ${ colors[row[header]] }`">
              <section class="text-tag">
                {{ row[header] }}
              </section>
            </span>
            <label class="checkbox ml-2" v-else-if="header === 'Conferido'">
              <input type="checkbox" v-model="row[header]" @change="changeCheck(row)" />
              <span class="checkmark">
                <Check />
              </span>
            </label>
            <div class="actions" v-else-if="(header === 'Ver menos' || header === '' || header === 'Detalhes') && i !== 0">
              <b-button v-b-toggle="`collapse-${row.id}-${index}`" class="toggle-btn" @click="rotateIcon"
                :disabled="!row.Detalhes.length">
                <ChevronDown class="icon" />
              </b-button>

              <Icon v-if="!$route.path.includes('lotes-enviados')">
                <File
                  class="icon-gray"
                  @click="openHistoric(row.id)"
                  v-b-tooltip.hover title="Histórico de ações"
                />
              </Icon>
              <Icon v-if="!$route.path.includes('lotes-enviados')">
                <Clip
                  class="icon-gray"
                  @click="row.files_count ? openDocumentListModal(row) : openDocumentModal(row)"
                  v-b-tooltip.hover 
                  :title="row.files_count ? 'Abrir arquivos' : 'Anexar arquivos'"
                />
              </Icon>
              <Icon v-if="row['Tipo de guia'] === 'SP/SADT' && url.includes('/faturamento-tiss/relatorio-de-guias')">
                <Copy
                  class="icon-gray"
                  @click="duplicateSPSADTGuide(row?.id)"
                  v-b-tooltip.hover 
                  title="Duplicar guia"
                />
              </Icon>

              <Icon 
                v-if="!$route.path.includes('lotes-enviados')"
                :tooltip="row['Situação'] !== 'Finalizada' ? 'Editar e ver detalhes' : 'Ver Detalhes'" placement="left"
              >
                <Edit class="icon" @click="getGuide(row)" />
              </Icon>

              <Icon v-if="canRemove && row['Lote'] !== 'Sim' && !$route.path.includes('lotes-enviados')">
                <Trash
                  class="icon trash"
                  @click="removeGuide(row)"
                  v-b-tooltip.hover title="Remover guia"
                />
              </Icon>

              <Icon 
                v-if="canDelete && !$route.path.includes('lotes-enviados')"
                v-can="'FatTissRelGui2'"
              >
                <Trash
                  class="icon trash"
                  @click="onDelete(row)"
                  v-b-tooltip.hover title="Excluir"
                />
              </Icon>
            </div>
            <div v-else-if="header === 'Arquivo'">
              <b-button v-if="row['Arquivo'] === 'Sim'" variant="link" @click="openDocumentListModal(row)">
                Abrir arquivos
              </b-button>
              <b-button v-else variant="link" @click="openDocumentModal(row)">
                Anexar arquivos
              </b-button>
            </div>
            <div class="multi-content" v-else-if="typeof row[header] === 'array' || typeof row[header] === 'object'">
              <div :id="`el-${i}`" class="multi-content-container">
                <p class="text-container">{{ row[header][0] }}</p>
                <b-badge variant="primary" v-if="row[header].length > 1">
                  +{{ row[header].length -1 }}
                </b-badge>
              </div>
              <div class="popover-element-container">
                <p v-for="(professional, j) in row[header]" :key="j">
                  {{ professional }}
                </p>
              </div>
            </div>
            <Ellipsis v-else-if="header === 'Paciente/Guia'">
              <p v-html="row[header]"></p>
            </Ellipsis>
            <p v-else v-html="row[header]"></p>
          </td>
        </tr>
        <tr class="collapsable-row">
          <td colspan="9">
            <b-collapse :id="`collapse-${row.id}-${index}`" class="collapse">
              <b-table-simple class="collapse-table mt-3">
                <thead>
                  <tr>
                    <th v-for="header in subHeaders" :key="header" scope="col">
                      {{ header }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(collapseRow, index) in row['Detalhes']" :key="index">
                    <td v-for="header in subHeaders" :key="header">
                      <span v-if="header === 'Situação' &&  typeof collapseRow[header] === 'string'" class="situation-tag" :style="`background: ${ colors[collapseRow[header]] }`">
                        {{ collapseRow[header] }}
                      </span>
                      <p v-else-if="typeof collapseRow[header] === 'array'">{{ collapseRow[header][0] }}</p>
                      <p v-else>{{ typeof collapseRow[header] === 'string' ? collapseRow[header] : '-' }}</p>
                    </td>
                  </tr>
                </tbody>
              </b-table-simple>
            </b-collapse>
          </td>
        </tr>
      </tbody>
      <tr>
        <td :class="{ 'spacing': openedMenu }"></td>
      </tr>
    </b-table-simple>

    <noFilterResults v-if="!rows?.length && !loading"
      message="Os filtros aplicados não geraram nenhum resultado." />
  </section>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'
import { TissGuide } from '@/modules/tiss/manageGuides/utils/statuses'

export default {
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    noFilterResults: () => import('@/components/General/noFilterResults'),
    Check: () => import('@/assets/icons/check.svg'),
    Trash: () => import( '@/assets/icons/trash.svg'),
    Edit: () => import( '@/assets/icons/edit.svg'),
    Icon: () => import('@/components/General/Icon'),
    Ellipsis: () => import('@/components/General/Ellipsis'),
    Clip: () => import('@/assets/icons/clip2.svg'),
    File: () => import('@/assets/icons/fileHistory.svg'),
    Copy: () => import('@/assets/icons/copy.svg'),
  },
  props: {
    headers: Array,
    subHeaders: Array,
    rows: Array,
    checkable: Boolean,
    getGuide: Function,
    openHistoric: Function,
    openDocumentModal: Function,
    openDocumentListModal: Function,
    status: String,
    addAndOpenGuide: Function,
    loading: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    canRemove: {
      type: Boolean,
      default: false
    },
    generateByselection: {
      type: Boolean,
      default: false
    },
    selecteds: Array,
    removeSelGuide: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      selectAll: false,
      watchAllSelected: true,
      tissGuide: {},
      colors: {
        [TissGuide.GUIDE_SITUATION_OPEN]: '#FCDEC2',
        [TissGuide.GUIDE_SITUATION_IN_PREPARATION]: '#FCDEC2',
        [TissGuide.GUIDE_SITUATION_PAYED]: 'var(--states-success-soft-green)',
        [TissGuide.GUIDE_SITUATION_PARTIALLY_PAID]: '#E7FFDB',
        [TissGuide.GUIDE_SITUATION_GLOSSED]: '#FED2CE',
        [TissGuide.GUIDE_SITUATION_AUTHORIZED]: '#E7ECFE;',
      },
      selectedType: null,
      hideRows: false,
      openedMenu: false,
      maxGuidesPerBatch: null,
    }
  },
  computed: {
    url() {
      return window.location.href
    }
  },        
  methods: {
    parseDate(date) {
      return this.moment(date).format('DD/MM/YYYY')
    },
    handleCheckbox(value) {
      if (!value && this.selectAll) {
        this.watchAllSelected = false
        this.selectAll = false
        setTimeout(() => {
          this.watchAllSelected = true
        }, "1000")
      }
    },
    async changeCheck(value) {
      const isLoading = this.$loading.show()
      try {
        await this.api.updateNewTissGuide(value.id, {
          conferred: value.Conferido,
          clinic_id: this.clinic.id
        })
        if (value.Conferido) {
          this.$toast.success('Guia conferida com sucesso!')
        } else {
          this.$toast.success('Guia desmarcada com sucesso!')
        }
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    rotateIcon($event) {
      const icon = $event.target
      icon.classList.toggle('rotate')
    },
    async getMaxGuidesBatch() {
      const isLoading = this.$loading.show()
      if(!(this.rows.length && this.rows[0]["Id Config"])) return
      try {
        const res = await this.api.getMaxGuidesBatch(this.rows[0]["Id Config"])
        this.maxGuidesPerBatch = parseInt(res.data)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    selectRow(row) {
      const index = this.selecteds.indexOf(row)
      if (index === -1) {
        if (this.selecteds.length < this.maxGuidesPerBatch) {
          if (this.selecteds.length === 0) {
            this.selectedType = row['Tipo de guia']
            this.$emit('setSelectedType', row['Tipo de guia'].toString())
          }
          this.selectedType === row['Tipo de guia'] ?
            this.selecteds.push(row)
            :
            this.$toast.error('Apenas guias do mesmo tipo na geração do lote')

          if (this.selecteds.length === this.maxGuidesPerBatch) this.$toast.info(`Limite de ${this.maxGuidesPerBatch} guias por lote atingido`)
        }
      }
      else {
        this.selecteds.splice(index, 1)
        if (!this.selecteds.length && this.selectedType) this.selectedType = null
      }
    },
    removeSelectGuide(row) {
      this.$toast.success('Guia removida com sucesso')
      const index = this.rows.indexOf(row)
      this.rows.splice(index, 1)
      if (this.rows.length === 0) this.$bvModal.hide('lots-by-selection-modal')
    },
    removeGuide(row) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente remover a Guia <span>${row['Tipo de guia']} - Nº ${row['N° da guia']} do paciente ${row.Paciente}</span> da fatura atual?</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
       }).then(res => {
        if (res.isConfirmed) {
          this.$emit('onRemoveGuide', row)
        }
      })
    },
    onDelete(row) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente excluir a Guia <span>${row['Tipo de guia']} - ${row['Paciente/Guia']}</span>? Esta ação não poderá ser desfeita.</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
       }).then(res => {
        if (res.isConfirmed) {
          this.$emit('onDeleteGuide', row)
        }
      })
    },
    async duplicateSPSADTGuide(id){
      const isLoading = this.$loading.show()
      try {
        const {data} = await this.api.duplicateSPSADTGuide(id)
        this.addAndOpenGuide(data)
        this.$toast.success('Guia duplicada com sucesso!')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
  },
  watch: {
    selectAll: function (newValue) {
      if (this.watchAllSelected) {
        this.rows.map(row => {
          if(row.Situação !== 'Enviado' || (row.Situação === 'Enviado' && this.status === 'Recebido') ) {
            row.checked = newValue
          }
        })
      }
    },
    generateByselection: {
      async handler(value) {
        if (value) {
          this.getMaxGuidesBatch()
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" src="@/assets/scss/table.scss" />

<style lang="scss">
#guides-table {
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  table-layout: fixed;
  margin: 5px 0;

  thead {
    font-weight: 600;
    color: var(--dark-blue);

    tr {
      background-color: var(--neutral-100);
    }
  }

  tbody {
    color: var(--type-active);
    font-weight: 600;

  }

  .spacing {
    height: 120px;
  }

  td {
    max-width: 350px !important;
  }

  td,
  th {
    @media (max-width: 1330px) {
      font-size: 12px;
    }

    .situation-tag {
      width: 100%;
      border-radius: 16px;
      padding: 5px 10px;
      background-color: var(--neutral-300);
      display: inline-block;
      text-align: center;
    }

    .text-tag {
      text-overflow: ellipsis !important;
      overflow: hidden !important;
      white-space: nowrap !important;
    }

    .toggle-btn {
      background: var(--blue-100);
      border: none;
      padding: 5px !important;

      .icon {
        width: 25px;
        transition: all 500ms;
      }
    }

    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .multi-content {
      display: flex;
      align-items: center;
      
      .multi-content-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .text-container {
          max-width: 10ch !important;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .popover-element-container {
        visibility: hidden !important;
        position: relative;
        padding: 10px;
        border-radius: 8px;
        border: 1px solid var(--neutral-300);
        background-color: var(--neutral-000);
        // margin-top: 20px;
      }
    }

    .multi-content:hover {
      .popover-element-container {
        visibility: visible !important;
        p {
          max-width: 20ch !important;
        }
      }
    }

    .checkbox {
      margin-left: 25px !important;
    }

    .actions {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }

    .options {
      cursor: pointer;
      width: 24px;
      height: 28px;
      position: relative;
      display: inline-block;

      .more-icon {
        width: 24px;
        height: 24px;
        stroke: var(--neutral-500);
        transition: all 0.5s;
        cursor: pointer;
      }

      .menu {
        z-index: 10;
        min-width: 180px;
        position: absolute;
        top: 28px;
        right: 0;
        background: var(--neutral-000);
        border: 1px solid var(--neutral-200);
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1),
          0px 4px 10px -8px rgba(12, 29, 89, 0.2);
        border-radius: 8px;
        padding: 0;
        opacity: 0;
        transition: all 0.5s;
        flex-direction: column;
        align-items: start;
        display: none;

        .mtb-12 {
          margin: 12px 0;
        }

        .btn {
          font-weight: 600;
          width: 100%;

          &.remove {
            color: var(--states-error);
          }

          &.btn-link {
            text-align: left;
            padding: 12px 18px !important;
          }
        }
      }

      &:hover {
        .more-icon {
          transform: rotate(90deg);
        }

        .menu {
          opacity: 1;
          display: flex;
          z-index: 10;
        }
      }
    }
  }

  .hide-btn {
    color: var(--blue-700);
    text-decoration: underline;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  td:last-child {
    overflow: visible;
  }

  .collapsable-row {
    td {
      border-bottom: 0.5px dotted var(--neutral-300);
      padding: 0;
    }

    .collapse-table {
      width: 100%;

      margin: 0 auto 20px auto;

      thead {
        tr {
          border-radius: 8px !important;
          background-color: var(--neutral-200);

          th {
            color: var(--dark-blue);
            font-weight: 600;
            font-size: 16px;
          }

          th:nth-child(1) {
            border-top-left-radius: 8px;
          }

          th:last-child {
            border-top-right-radius: 8px !important;
          }
        }
      }

      tbody {
        border: 1px dashed var(--neutral-200);

        tr {
          td {
            padding: 15px 5px;
          }

          td:nth-child(4) {
            max-width: 35ch;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 2px;
    // stroke: var(--blue-500);
    cursor: pointer;

    &.trash {
      fill: none;
      stroke: var(--states-red-soft);
    }

    &.trash:hover {
      stroke: var(--states-error);
    }
  }
}

.text-align-center {
  text-align: center;
}

.rotate {
  transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}

.icon-gray {
  cursor: pointer;
  stroke: var(--neutral-100);
}
</style>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: var(--neutral-000);
  border-radius: 0 0 8px 0;
}

::-webkit-scrollbar-thumb {
  background-color: var(--neutral-300);
  border-radius: 100px;
  border: 3px solid var(--neutral-000);
}

::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: var(--neutral-200);
}

</style>
